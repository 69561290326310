// Helper functions
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

import { generateRandomId } from '../Services/CalendarService';
import { IMixlEvent, IMixlTask } from './Types';

export function getDefaultWorkTemplate(workingHours: { workingStartTime: string; workingEndTime: string }) {
  const { workingStartTime, workingEndTime } = workingHours;
  const workStartTime = DateTime.fromISO(workingStartTime);
  const createdAt = DateTime.now().toISO();
  const meetingStartTime = workStartTime.toFormat('HH:mm');
  const meetingEndTime = workStartTime.plus({ hours: 1 }).toFormat('HH:mm');

  const workTemplate = [
    {
      id: generateRandomId(),
      start: meetingStartTime,
      end: meetingEndTime,
      title: 'Meeting',
      color: '#7F96FF',
      createdAt
    }
  ];

  const workDuration = calculateHourDifference(workingStartTime, workingEndTime);
  if (workDuration > 6) {
    const lunchBreak = {
      id: generateRandomId(),
      start: workStartTime.plus({ hours: 4 }).toFormat('HH:mm'),
      end: workStartTime.plus({ hours: 5 }).toFormat('HH:mm'),
      title: 'Break',
      color: '#00C57E',
      createdAt
    };

    workTemplate.push(lunchBreak);
  }

  return workTemplate;
}
export function calculateHourDifference(startTimeISO: string, endTimeISO: string): number {
  // Convert the start and end times to Luxon DateTime objects
  const start: DateTime = DateTime.fromISO(startTimeISO);
  const end: DateTime = DateTime.fromISO(endTimeISO);

  // Calculate the difference in hours
  const hourDiff: number = end.diff(start, 'hours').hours;

  return hourDiff;
}

// Generate a list of hours within the working hours
const generateAvailableHours = (workingStartTime, workingEndTime) => {
  const hours = [];
  const startHour = new Date(workingStartTime).getHours();
  const endHour = new Date(workingEndTime).getHours();
  for (let i = startHour; i < endHour; i++) {
    hours.push(i.toString().padStart(2, '0'));
  }
  return hours;
};

// Convert time in "HH:mm" format to a Date object
const convertTimeToDate = (time, referenceDate) => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date(referenceDate);
  date.setHours(hours, minutes, 0, 0);
  return date;
};

// Check if a time overlaps with any events in a list
const isTimeAvailable = (time, events) => {
  return !events.some((event) => {
    const eventStartDate = new Date(event.startTimestamp);
    const eventStart = eventStartDate.getTime();
    const backupEndTime = DateTime.fromMillis(eventStart).plus({ hours: 1 }).startOf('hour').toMillis();
    const endEventTimestamp = event?.endTimestamp ?? backupEndTime;
    const eventEnd = new Date(endEventTimestamp).getTime();
    const taskStart = time.getTime();
    const taskEnd = new Date(time).setHours(time.getHours() + 1);
    return taskStart < eventEnd && taskEnd > eventStart;
  });
};

// Check if a time overlaps with any events in the workTemplate
const isTimeAvailableInTemplate = (time, workTemplate, referenceDate) => {
  return !workTemplate.some((event) => {
    const eventStart = convertTimeToDate(event.start, referenceDate).getTime();
    const eventEnd = convertTimeToDate(event.end, referenceDate).getTime();
    const taskStart = time.getTime();
    const taskEnd = new Date(time).setHours(time.getHours() + 1);
    return taskStart < eventEnd && taskEnd > eventStart;
  });
};

export const isFinalHourAvailable = (workingHours, eventsWithData: IMixlEvent[]) => {
  console.log('isFinalHourAvailable: eventsWithData ', eventsWithData);
  const workingStartTime = new Date(workingHours.workingStartTime);
  const workingEndTime = new Date(workingHours.workingEndTime);
  const hoursList = generateAvailableHours(workingStartTime, workingEndTime);
  const finalHourText = hoursList[hoursList.length - 1];
  const finalHour = DateTime.now().set({ hour: finalHourText, minute: 0, second: 0, millisecond: 0 }).toJSDate();
  const scheduledTasks = eventsWithData
    .filter((item) => {
      return item.type === 'todos' || item.type === 'learning';
    })
    .map((mixlEvent) => {
      const task = mixlEvent.task;
      const today = DateTime.now();
      const startTime = DateTime.fromMillis(task.startTimestamp);
      if (!today.hasSame(startTime, 'day')) {
        task.startTimestamp = today
          .set({
            hour: startTime.hour,
            minute: startTime.minute,
            second: startTime.second,
            millisecond: startTime.millisecond
          })
          .toMillis();
      }
      const endTime = DateTime.fromMillis(task.endTimestamp);
      if (!today.hasSame(endTime, 'day')) {
        task.endTimestamp = today
          .set({
            hour: endTime.hour,
            minute: endTime.minute,
            second: endTime.second,
            millisecond: endTime.millisecond
          })
          .toMillis();
      }
      return task;
    });

  console.log('isFinalHourAvailable: scheduledTasks ', scheduledTasks);
  const isHourAvailable = isTimeAvailable(finalHour, scheduledTasks);
  console.log('isFinalHourAvailable: isHourAvailable ', isHourAvailable);
  if (isHourAvailable) {
    console.log('isFinalHourAvailable: add task here');
    return finalHour;
  }

  return null;
};

/*
  case 1: taskCount: 2, scheduledTaskCount: 2, availableHours = 9
  workingHours = {
    "workingStartTime": "2024-09-17T13:00",
    "workingEndTime": "2024-09-17T22:00"
  }

*/
// Main function to schedule tasks
export const scheduleMixlTasks = (
  tasks: Array<IMixlTask>,
  scheduledTasks: Array<IMixlTask>,
  workTemplate: Array<unknown>,
  workingHours: { workingStartTime: string; workingEndTime: string }
) => {
  console.log('scheduleMixlTasks: ', workTemplate, workingHours);
  const newTasks = [...tasks];
  const newScheduledTasks = [];
  const currentDate = new Date();

  const workingStartTime = new Date(workingHours.workingStartTime);
  const workingEndTime = new Date(workingHours.workingEndTime);

  // Generate available hours list
  let hoursList = generateAvailableHours(workingStartTime, workingEndTime);
  console.log('generated hours ', hoursList);
  const currentHour = new Date().getHours().toString().padStart(2, '0');

  console.log('current hour ', currentHour);
  hoursList = hoursList.filter((hour) => hour > currentHour);

  if (hoursList.length === 0) {
    hoursList = generateAvailableHours(workingStartTime, workingEndTime);
  }

  const finalHoursList = [...hoursList];

  let taskCounter = 0;
  let repeatCounter = 1;
  console.log('available hour list ', finalHoursList);

  // Iterate over the hours and assign tasks
  for (let i = 0; i < finalHoursList.length; i++) {
    let taskData = cloneDeep(newTasks[taskCounter]);
    if (taskCounter >= newTasks.length) {
      console.log('scheduleMixlTasks: old', taskCounter, newTasks.length);
      taskCounter = 0;
      ++repeatCounter;
      console.log('scheduleMixlTasks: new', taskCounter, newTasks.length);
    }
    if (repeatCounter > 1) {
      taskData = cloneDeep(newTasks[taskCounter]);
      taskData['isNew'] = true;
      taskData.type = 'custom';
      taskData.name = `Part ${repeatCounter}: ${taskData.name}`;
    }

    // Create a new date object for each hour in the list
    const dateWithHour = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      finalHoursList[i],
      0,
      0,
      0
    );

    // Check if the time is available
    if (
      isTimeAvailable(dateWithHour, scheduledTasks) &&
      isTimeAvailableInTemplate(dateWithHour, workTemplate, currentDate)
    ) {
      const enddateWithHour = new Date(dateWithHour);
      enddateWithHour.setHours(dateWithHour.getHours() + 1);

      // Ensure the end time is within the working hours
      if (enddateWithHour > workingEndTime) break;

      console.log(i, taskCounter, dateWithHour, enddateWithHour);
      taskData.startTimestamp = dateWithHour.getTime();
      taskData.endTimestamp = enddateWithHour.getTime();
      taskData.isTodayTask = true;
      newScheduledTasks.push(taskData);
      taskCounter++;
    }
  }

  return { newScheduledTasks, hasRepeatedTasks: Boolean(repeatCounter > 1) };
};
