import { cloneDeep } from 'lodash';

import { defaultUserDetails, gloqalBusiness } from './type';

export const terms_date = 'February 14, 2024';

const user_details = cloneDeep(defaultUserDetails);

const gloqalBusinessState = {
  // UPDATE WHEN WE UPDATE TERMS OF SERVICE
  termsDate: terms_date,
  showTermsModal: false,
  brandName: '',
  user_details,
  slackCredential: null,
  gcalCredential: null,
  jiraCredential: null,
  clickupCredential: null,
  notionCredential: null,
  openUploadPhotoModal: false,
  isFirstTimeUser: false,
  openDeleteModal: false,
  activeIndex: 0,
  isPremiumUser: false,
  showInviteModal: false,
  showUserProfile: false
};

export const businessReducer = (state = gloqalBusinessState, action) => {
  switch (action.type) {
    case gloqalBusiness.SHOW_DELETE_ACCOUNT_MODAL: {
      return { ...state, openDeleteModal: action.isOpen };
    }
    case gloqalBusiness.UPDATE_NOTION_AUTH: {
      return { ...state, notionCredential: action.payload };
    }
    case gloqalBusiness.UPDATE_SLACK_AUTH: {
      return { ...state, slackCredential: action.payload };
    }
    case gloqalBusiness.SHOW_TERMS_MODAL: {
      return {
        ...state,
        showTermsModal: action.value
      };
    }
    case gloqalBusiness.BRAND_NAME: {
      return {
        ...state,
        brandName: action.payload
      };
    }
    case gloqalBusiness.COMPANY_DETAILS: {
      return {
        ...state,
        user_details: {
          ...state.user_details,
          ...action.payload
        }
      };
    }
    case gloqalBusiness.UPLOAD_PHOTO_MODAL: {
      return {
        ...state,
        openUploadPhotoModal: action.payload
      };
    }
    case gloqalBusiness.SET_FIRST_TIME_USER: {
      return {
        ...state,
        isFirstTimeUser: action.payload
      };
    }

    case gloqalBusiness.UPDATE_JIRA_AUTH: {
      return { ...state, jiraCredential: action.payload };
    }
    case gloqalBusiness.UPDATE_CLICKUP_AUTH: {
      return { ...state, clickupCredential: action.payload };
    }
    case gloqalBusiness.UPDATE_GCAL_AUTH: {
      return { ...state, gcalCredential: action.payload };
    }
    case gloqalBusiness.SET_ACTIVE_INDEX: {
      return {
        ...state,
        activeIndex: action.payload
      };
    }
    case gloqalBusiness.SET_HAS_PREMIUM_SUBSCRIPTION: {
      return {
        ...state,
        isPremiumUser: action.payload
      };
    }
    case gloqalBusiness.SET_SHOW_INVITE_MODAL: {
      return {
        ...state,
        showInviteModal: action.payload
      };
    }
    case gloqalBusiness.SET_SHOW_PROFILE: {
      return {
        ...state,
        showUserProfile: action.payload
      };
    }
    default:
      return state;
  }
};
